import FingerprintJS from '@fingerprintjs/fingerprintjs';

/**
 * Генерирует постоянный UID пользователя на основе отпечатка браузера.
 * Сохраняет UID в localStorage для обеспечения постоянства.
 *
 * @returns {Promise<string>} Уникальный идентификатор пользователя
 */
export function getUID(): string {
    return localStorage.getItem('userUID') || ''
}

function setUID(uid: string): void {
    localStorage.setItem('userUID', uid);
}

export async function loadUID(): Promise<string> {

    // Проверяем, есть ли уже UID в localStorage
    const existingUID = localStorage.getItem('userUID');
    if (existingUID) {
        return existingUID;
    }

    // Инициализируем FingerprintJS
    const fp = await FingerprintJS.load();

    // Получаем отпечаток браузера
    const result = await fp.get();

    const uid = result.visitorId;
    // Сохраняем UID в localStorage
    setUID(uid)

    return uid;
}